// noinspection DuplicatedCode

import React, { ReactElement, memo, useEffect } from 'react'

import isEqual from 'lodash.isequal'

import { SidebarContainer, SidebarWrapper } from './Sidebar.styled'
import { useModal } from 'mmfintech-commons'
import { useLocation } from 'react-router'

type SidebarComponentProps = {
  content: ReactElement
  options: {
    closeIconPosition?: 'default'
    closeOnClickOutside?: boolean
    closeOnEscape?: boolean
    hideCloseIcon?: boolean
    scrollable?: boolean
    maxWidth?: number
    onClose?: () => void
    transparent?: boolean
    size?: 'auto' | 'small' | 'medium' | 'large' | 'extra-large' | 'horizontal' | 'centered'
  }
  visible: boolean
  onClose: () => void
}

const SidebarComponent: React.FC<SidebarComponentProps> = ({ content, visible, onClose, options }) => {
  const { ref, handleMouseDown } = useModal({
    options,
    visible,
    onClose
  })

  const location = useLocation()

  useEffect(() => {
    if (visible) {
      typeof onClose === 'function' && onClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <SidebarWrapper $visible={visible} onMouseDown={handleMouseDown} ref={ref}>
      <SidebarContainer
        data-test='modal-container'
        onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
        $visible={visible}>
        {content}
      </SidebarContainer>
    </SidebarWrapper>
  )
}

export const Sidebar = memo(SidebarComponent, (prevProps, nextProps) => isEqual(prevProps, nextProps))
