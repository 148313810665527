export const ExchangeIcon = ({ color = '#15cf80', stroke = 'white' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
      <g>
        <title>Layer 1</title>
        <circle cx="15" cy="15" fill={color} id="svg_1" r="15"/>
        <path d="m7.56,16.04l0,1.06c0,1.45 1.18,2.63 2.63,2.63" fillOpacity="0" id="svg_2" stroke={stroke} strokeLinecap="round"/>
        <path d="m19.23,16.73l2.79,3.1l-2.79,3.09" fillOpacity="0" id="svg_5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" transform="matrix(1 0 0 1 0 0)"/>
        <path d="m10.28,19.74l10.95,0" fillOpacity="0" id="svg_4" stroke={stroke} strokeLinecap="round" transform="matrix(1 0 0 1 0 0)"/>
        <path d="m5.23,7.59l2.79,3.1l-2.79,3.09" fillOpacity="0" id="svg_8" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" transform="matrix(1 0 0 1 0 0) rotate(180 7.125 10.6875)"/>
        <path d="m7.21,10.75l10.95,0" fillOpacity="0" id="svg_9" stroke={stroke} strokeLinecap="round" transform="matrix(1 0 0 1 0 0)"/>
        <path d="m18.87,10.78l0,1.06c0,1.45 1.18,2.63 2.63,2.63" fillOpacity="0" id="svg_10" stroke={stroke} strokeLinecap="round" transform="rotate(180 19.9375 12.625)"/>
      </g>
    </svg>
  )
  