import { Banner } from './Banner'

import { tr } from 'mmfintech-commons'
import { useOnboarding } from '../../../hooks'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

export const SumSubBanner = ({ className = undefined }) => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingProvider, onboardingStatus } = merchant || {}

  const { startOrContinueOnboarding } = useOnboarding()

  if (accountType === MerchantAccountTypeEnum.PROSPECT && onboardingProvider === 'SUMSUB') {
    if (
      onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
      onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
      onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
    ) {
      return (
        <Banner
          title={tr('FRONTEND.ONBOARDING.COMPLETE_TITLE', 'Complete your account')}
          content={tr(
            'FRONTEND.ONBOARDING.COMPLETE_TEXT',
            'Verify to get access to all features and increased payment limits.'
          )}
          handleClick={() => startOrContinueOnboarding()}
          className={className}
        />
      )
    }

    if (
      onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
      onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
      onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
    ) {
      return (
        <Banner
          content={tr('FRONTEND.ONBOARDING.UNDER_REVIEW_MESSAGE', 'Your account verification is under review.')}
          className={className}
        />
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
      return (
        <Banner
          content={tr(
            'FRONTEND.ONBOARDING.REJECTED_MESSAGE',
            'Please contact Customer Service to get support on your account verification.'
          )}
          className={className}
        />
      )
    }
  }

  return null
}
