import styled from 'styled-components'

export const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`

export const AlertContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }

  .button {
    width: 100%;
    max-width: 30rem;
  }
`

export const TryAgainButton = styled.button`
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 1.5rem;

  color: #ffffff;
  background: #ff4c4d;
  box-shadow: 0 5px 17px rgba(255, 165, 159, 0.5);

  border-radius: 5px;
  border: none;
`
