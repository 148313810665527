import { useContext } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { ManageCookies } from '../ManageCookies'
import { ConsentContent, ConsentFooter, CookieConsentWrapper } from './CookieConsent.styled'

import settings from '../../settings'
import { tr, GlobalContext } from 'mmfintech-commons'

export const CookieConsent = ({ setCookie }) => {
  const { modalShow } = useContext(GlobalContext)

  const onAcceptAllClick = () => {
    setCookie(
      'cookie.consent',
      JSON.stringify({
        necessaryCookies: true,
        optionalCookies: true
      }),
      {
        days: 365,
        maxAge: 31536000,
        domain: settings.cookieDomain
      }
    )
  }

  const onManageCookiesClick = () => {
    modalShow({
      options: { maxWidth: 950, size: 'auto' },
      content: <ManageCookies setCookie={setCookie} />
    })
  }

  return (
    <CookieConsentWrapper>
      <ConsentContent>
        <div>
          <div className='title'>{tr('FRONTEND.COOKIE_CONSENT.COOKIE_POLICY', 'Cookies Policy')}</div>
          {tr(
            'FRONTEND.COOKIE_CONSENT.PERSONALISED_XP',
            'We use cookies to personalize your experience on Tomo Pay portal. They are all about to personalize content and ads, provide social media features and to analyze our traffic.'
          )}
        </div>
      </ConsentContent>

      <ConsentFooter>
        <Button
          className='manage'
          color='secondary'
          text={tr('FRONTEND.COOKIE_CONSENT.MANAGE_COOKIES', 'Manage Cookies')}
          onClick={onManageCookiesClick}
          data-test='button-manage-cookies'
        />
        <Button
          className='consent'
          color='round-primary'
          text={tr('FRONTEND.COOKIE_CONSENT.ACCEPT_ALL', 'Accept All')}
          onClick={onAcceptAllClick}
          data-test='button-accept-all'
        />
      </ConsentFooter>
    </CookieConsentWrapper>
  )
}
