import React, { useContext, useEffect, useState } from 'react'

import DatePicker from 'react-datepicker'
import fileDownload from 'js-file-download'

import { SelectAccount } from '../../../components'
import { Button, ErrorDisplay, Input, RadioButtonGroup } from 'mmfintech-portal-commons'
import { DateContainer, DateWrapper, StatementWrapper, StyledForm } from './TransactionsExportModal.styled'

import {
  endpoints,
  selectCurrentLanguage,
  useAppSelector,
  useFileDownloader,
  useGetMerchantQuery
} from 'mmfintech-backend-api'

import {
  GlobalContext,
  monthOptions,
  tr,
  useFormValues,
  currentMonth,
  currentYear,
  firstDateOfMonth,
  todayDate,
  prepareDate,
  compareDates,
  lastDayOfMonth,
  currentDay,
  fixDateOnly,
  formatDate
} from 'mmfintech-commons'

import { StatementPeriodTypeEnum } from 'mmfintech-commons-types'

import CalendarIcon from '../../../images/icons/calendar.svg'

export const TransactionsExportModal = ({ account, accounts, dateFrom, dateTo }) => {
  const { modalHide } = useContext(GlobalContext)

  const selectedLanguage = useAppSelector(selectCurrentLanguage)

  const { data: merchant } = useGetMerchantQuery()

  const { download, error: downloadError, fetching: downloadFetching, reset: downloadReset } = useFileDownloader({})

  const [error, setError] = useState(null)
  const [periodType, setPeriodType] = useState('CUSTOM')
  const [selectedAccount, setSelectedAccount] = useState(account)

  const formValues = useFormValues({
    quarter: { value: 'Q1' },
    month: { value: currentMonth() + 1 },
    year: { value: currentYear() },
    startDate: { value: dateFrom ? dateFrom : firstDateOfMonth() },
    endDate: { value: dateTo ? dateTo : todayDate() }
  })

  //const fiscalMonths = ['M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'M9', 'M10', 'M11', 'M12']
  const fiscalQuarters = ['Q1', 'Q2', 'Q3', 'Q4']

  const getYears = () => {
    const { createdOn } = merchant || {}
    const year2 = prepareDate(createdOn).getFullYear()
    const res = []
    for (let y = currentYear(); y >= year2; y--) {
      res.push(y)
    }
    return res
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!selectedAccount) {
      setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_ACCOUNT', 'Account is not selected.'))
      return
    }

    let period: any

    switch (periodType) {
      case StatementPeriodTypeEnum.MONTHLY:
        const startDate = new Date(formValues.getValue('year'), formValues.getValue('month') - 1, 1)
        if (compareDates(startDate, todayDate()) > 0) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        let endDate = lastDayOfMonth(startDate)
        if (endDate.getFullYear() === currentYear() && endDate.getMonth() === currentMonth()) {
          endDate.setDate(currentDay())
        }
        if (compareDates(endDate, todayDate()) > 0) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        period = {
          periodType: StatementPeriodTypeEnum.CUSTOM,
          startDate: fixDateOnly(startDate),
          endDate: fixDateOnly(endDate)
        }
        // period = {
        //   periodType,
        //   month: formValues.getValue('month'), // [ M1, M2, M3, M4, M5, M6, M7, M8, M9, M10, M11, M12 ]
        //   year: formValues.getValue('year')
        // }
        break
      case StatementPeriodTypeEnum.QUARTERLY:
        period = {
          periodType,
          quarter: formValues.getValue('quarter'), // [ Q1, Q2, Q3, Q4 ]
          year: formValues.getValue('year')
        }
        break
      case StatementPeriodTypeEnum.YEARLY:
        period = {
          periodType,
          year: formValues.getValue('year')
        }
        break
      default:
        if (
          compareDates(formValues.getValue('startDate'), todayDate()) > 0 ||
          compareDates(formValues.getValue('endDate'), todayDate()) > 0
        ) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        period = {
          periodType: StatementPeriodTypeEnum.CUSTOM,
          startDate: fixDateOnly(formValues.getValue('startDate')),
          endDate: fixDateOnly(formValues.getValue('endDate'))
        }
    }

    const data = {
      accountId: selectedAccount.id,
      period: period
    }

    void download({
      url: endpoints.transactions.getStatementCSV(),
      data,
      onSuccess: (response, filename) => {
        fileDownload(response, filename || 'transactions.csv', 'text/csv')
        modalHide()
      }
    })
  }

  useEffect(() => {
    return () => {
      downloadReset()
    }
  }, [])

  return (
    <StatementWrapper data-test='transactions-export-period-modal'>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <div className='title'>{tr('FRONTEND.STATEMENT.EXPORT.TITLE_CSV', 'Transactions CSV Period')}</div>

        <SelectAccount
          noShadow
          accounts={accounts}
          label={
            <>
              {tr('FRONTEND.STATEMENT.EXPORT.ACCOUNT_LABEL', 'Account')}
              <span className='asterisk'>*</span>
            </>
          }
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />

        <RadioButtonGroup
          options={[
            {
              value: StatementPeriodTypeEnum.MONTHLY,
              label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_MONTHLY', 'Monthly'),
              dataTest: StatementPeriodTypeEnum.MONTHLY
            },
            // {
            //   value: statementPeriodType.QUARTERLY,
            //   label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_QUARTERLY', 'Quarterly'),
            //   dataTest: statementPeriodType.QUARTERLY
            // },
            // {
            //   value: statementPeriodType.YEARLY,
            //   label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_YEARLY', 'Yearly'),
            //   dataTest: statementPeriodType.YEARLY
            // },
            {
              value: StatementPeriodTypeEnum.CUSTOM,
              label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_CUSTOM', 'Custom'),
              dataTest: StatementPeriodTypeEnum.CUSTOM
            }
          ]}
          selected={periodType}
          setSelected={(value: string) => setPeriodType(value)}
        />

        {periodType === StatementPeriodTypeEnum.CUSTOM ? (
          <div className='period-wrap'>
            <DateContainer>
              <label>
                {tr('FRONTEND.STATEMENT.EXPORT.DATE_FROM_LABEL', 'From')}
                <span className='asterisk'>*</span>
              </label>
              <DatePicker
                locale={selectedLanguage}
                maxDate={todayDate()}
                selected={formValues.getValue('startDate')}
                onChange={date => formValues.setValue('startDate', date)}
                calendarStartDay={1}
                customInput={
                  <DateWrapper>
                    <img src={CalendarIcon} alt='From' />
                    <span>{formatDate(formValues.getValue('startDate'))}</span>
                  </DateWrapper>
                }
              />
            </DateContainer>
            <DateContainer>
              <label>
                {tr('FRONTEND.STATEMENT.EXPORT.DATE_TO_LABEL', 'To')}
                <span className='asterisk'>*</span>
              </label>
              <DatePicker
                locale={selectedLanguage}
                maxDate={todayDate()}
                selected={formValues.getValue('endDate')}
                onChange={date => formValues.setValue('endDate', date)}
                calendarStartDay={1}
                customInput={
                  <DateWrapper>
                    <img src={CalendarIcon} alt='To' />
                    <span>{formatDate(formValues.getValue('endDate'))}</span>
                  </DateWrapper>
                }
              />
            </DateContainer>
          </div>
        ) : (
          <div className='year-month'>
            <Input
              type='select'
              data-test='year'
              menuPosition='fixed'
              label={tr('FRONTEND.STATEMENT.EXPORT.YEAR_LABEL', 'Year')}
              options={getYears().map(year => ({ label: year, value: year }))}
              {...formValues.registerInput('year')}
              required
            />

            {/*<Input
                type='select'
                data-test='month'
                menuPosition='fixed'
                label={'Fiscal month'}
                options={fiscalMonths.map(m => ({ label: m, value: m }))}
                {...formValues.register('month')}
              />*/}
            {periodType === StatementPeriodTypeEnum.MONTHLY && (
              <Input
                type='select'
                data-test='month'
                menuPosition='fixed'
                label={tr('FRONTEND.STATEMENT.EXPORT.MONTH_LABEL', 'Month')}
                options={monthOptions()}
                {...formValues.registerInput('month')}
                required
              />
            )}

            {periodType === StatementPeriodTypeEnum.QUARTERLY && (
              <Input
                type='select'
                data-test='quarter'
                menuPosition='fixed'
                label={tr('FRONTEND.STATEMENT.EXPORT.QUARTER_LABEL', 'Fiscal quarter')}
                options={fiscalQuarters.map(q => ({ label: q, value: q }))}
                {...formValues.registerInput('quarter')}
              />
            )}
          </div>
        )}

        <ErrorDisplay error={[downloadError, error]} />

        <Button
          type='submit'
          color='primary'
          text={tr('FRONTEND.STATEMENT.EXPORT.EXPORT_BUTTON', 'Export transactions')}
          loading={downloadFetching}
          data-test='button-generate'
        />
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={modalHide}
          disabled={downloadFetching}
          data-test='button-cancel'
        />
      </StyledForm>
    </StatementWrapper>
  )
}
