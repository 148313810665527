import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'mmfintech-portal-commons'
import { CloseIcon } from './CloseIcon'
import { LanguageMenu } from '../../LanguageMenu'
import { HamburgerMenuWrapper } from './HamburgerMenu.styled'

import { tr } from 'mmfintech-commons'
import { useAppDispatch } from '../../../hooks'
import { api, paths, selectCurrentUserStatus, useAppSelector, useLazyLogoutQuery } from 'mmfintech-backend-api'

import { LoginStatusEnum } from 'mmfintech-commons-types'

interface HamburgerMenuProps {
  opened: boolean
  height?: number
  maxWidth: number
  hideMenu: () => void
  Items: React.FC
}

export const HamburgerMenu = ({ opened, maxWidth, hideMenu, Items }: HamburgerMenuProps) => {
  const history = useHistory()

  const userStatus = useAppSelector(selectCurrentUserStatus)
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const [logout] = useLazyLogoutQuery()

  const dispatch = useAppDispatch()

  const handleLogoutClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    hideMenu()
    await logout().unwrap()
    dispatch(api.util.resetApiState())
    return false
  }

  return (
    <HamburgerMenuWrapper open={opened} maxMobileWidth={maxWidth}>
      <CloseIcon onClick={hideMenu} />
      <LanguageMenu hamburger={true} />
      <div className='items-container'>{isLoggedIn() && <Items />}</div>
      <div className='action-button'>
        {isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
            data-test='button-submit'
            onClick={handleLogoutClick}
          />
        )}
        {!isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.LOGIN', 'Log in')}
            data-test='button-submit'
            onClick={() => {
              history.push(paths.login())
              hideMenu()
            }}
          />
        )}
        {!isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.SIGNUP', 'Sign Up')}
            data-test='button-submit'
            onClick={() => {
              history.push(paths.signUp())
              hideMenu()
            }}
          />
        )}
      </div>
    </HamburgerMenuWrapper>
  )
}
