import styled from 'styled-components'

import HamburgerMenuIcon from '../../images/icons/hamburger-menu.svg'

export const BrandLogoWrapper = styled.div`
  a.brand-logo {
    justify-content: left; 
  }
`

export const Hamburger = styled.div`
  padding-right: 1rem;
  @media (min-width: 320px) {
    padding-right: 1.5rem;
  }

  div.title {
    color: #000000;
  }

  a {
    margin-bottom: 0;
    padding: 1.6rem 2.5rem;

    &.active-tab {
      border-bottom: none;
    }
  }
`

export const HamburgerIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  padding: 0;
  z-index: 10;
  border: none;
  cursor: pointer;
  background: transparent;

  :focus {
    outline: none;
  }

  svg:hover > * {
    fill: #bdbdbd;
  }

  .menu-icon {
    width: 2.5rem;
    height: 2.5rem;
    background: url(${HamburgerMenuIcon}) center center no-repeat;
  }
`

export const HeaderWrapper = styled.div<{ visible: boolean }>`
  grid-area: header;

  display: ${({ visible }) => (visible ? 'grid' : 'none')};
  grid-template-columns: 1fr minmax(320px, 1275px) 1fr;

  margin: 0;
  padding: 0;
  width: 100%;

  z-index: 99;

  color: #ffffff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;

  .overlay {
    z-index: 10;
    background: rgba(96, 96, 96, 0.5);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  a {
    white-space: nowrap;
  }
`

export const HeaderContainer = styled.nav<{ maxMobileWidth: number }>`
  grid-column: 2 / 3;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  padding: 0 1rem 0 2rem;

  @media (min-width: ${({ maxMobileWidth }) => `${maxMobileWidth}px`}) {
    padding: 0 2rem;
  }

  a,
  .invite-and-earn {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: center;
    cursor: pointer;

    width: 10rem;
    white-space: nowrap;

    height: 100%;
    color: #707881;
    text-align: center;
    text-decoration: none;
    border-bottom: 3px solid transparent;

    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    &.active-tab {
      color: #15cf80;

      font-weight: 600;
    }
    &.disabled {
      pointer-events: none;
    }

    @media (min-width: 1440px) {
      width: 13rem;
    }
  }
`

export const HeaderInnerContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 3rem;
  align-items: center;
`
