import styled from 'styled-components'

export const LogoWrapper = styled.div`
  grid-area: head-main;

  display: flex;
  justify-content: center;

  img {
    max-height: 56px;
  }

  @media (min-width: 640px) {
    width: auto;
  }

  &.auto-width {
    width: auto;
  }
`
