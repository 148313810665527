import styled from 'styled-components'

export const SecurityModalWrapper = styled.form`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  color: #000000;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;
  text-align: center;

  width: 92vw;
  max-width: 50rem;

  padding: 2rem;
  @media (min-width: 768px) {
    padding: 4rem 5rem 2rem;
  }

  .selection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    border-radius: 8px;
    background-color: rgba(143, 178, 193, 0.15);
    cursor: pointer;

    padding: 1.5rem;

    &:nth-of-type(1n) {
      margin-bottom: 1rem;
    }

    &.selected {
      background-color: rgba(21, 207, 128, 0.1);
      border: 1px solid rgba(21, 207, 128, 0.5);
    }

    .left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      span {
        font-size: 1.4rem;
        line-height: 1.6rem;
        margin: 0 1rem;
      }
    }
  }

  .change-2fa-submit-button {
    margin-top: 0 !important;
  }
  .change-2fa-phone-input-wrapper {
    margin-top: 2rem;

    .country-list {
      position: fixed;
    }
  }
  .password-terms,
  .radio-group {
    margin-bottom: 0;
  }

  .button {
    margin-top: 1rem;

    :first-of-type {
      margin-top: 2rem;
    }
  }
`

export const SecurityModalTitle = styled.div`
  color: #454a54;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: center;
  margin-bottom: 2rem;
`
