import styled from 'styled-components'

export const FilterWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  padding: 2rem 0;
`

export const FiltersContainer = styled.div`
  .input-wrapper {
    & > div {
      border-radius: 8px;
      border: 1px solid #e1e6ea;
      padding: 0;
    }

    label {
      color: #454a54;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.4rem;
      margin-bottom: 0.5rem;
      text-transform: none;
      padding-left: 1rem;
    }

    input {
      height: 3.5rem;
      font-size: 12px;
      line-height: 1.4rem;
      font-weight: 400;
      padding: 0 1rem;

      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }

  [data-test='date-input-from'],
  [data-test='date-input-to'] {
    max-width: none;
    width: 100%;

    @media (min-width: 565px) {
      max-width: 13rem;
    }

    & > div {
      border-radius: 8px;
      border: 1px solid #e1e6ea;
      height: 3.5rem;
      padding: 0 1rem;

      .icon {
        margin-right: 0;
      }

      input {
        color: #333333;
        cursor: pointer;
      }
    }
  }
`

export const FiltersTopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'balance-section' 'buttons-section' 'filter-section';
  grid-gap: 3rem;
  align-items: center;

  @media (min-width: 1024px) {
    grid-template-columns: auto auto 1fr auto;
    grid-template-areas: 'balance-section buttons-section spacer filter-section';
  }
`

export const FiltersBalance = styled.div`
  grid-column: balance-section;

  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 0.5rem;
  padding-bottom: 1.5rem;

  .label {
    color: #85a0ad;
    text-align: left;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  .balance {
    display: flex;
    gap: 1rem;

    .balance-text {
      color: #454a54;
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: 700;
    }
  }

`

export const FiltersButtons = styled.div`
  grid-column: buttons-section;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;
  place-content: center;

  .button {
    color: #454a54;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: auto;

    height: 4rem;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 1px 1px 4px 0 rgba(149, 149, 149, 0.25);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem 0 0.8rem;

    .icon.icon-left {
      margin-right: 5px;
    }
  }
`

export const FiltersInitial = styled.div`
  grid-column: filter-section;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 1rem;
  place-content: center;
`
