import { useEffect, useState } from 'react'

import cn from 'classnames'

import { ChevronDownIcon, ChevronUpIcon } from '../../icons'
import { MenuContainer, MenuDropDown, MenuWrapper } from './LanguageMenu.styled'

import settings from '../../settings'

import { useDropDownMenu, initializeLanguage, languages, storeLanguage } from 'mmfintech-commons'

import { useAppDispatch } from '../../hooks'
import {
  changeLanguage,
  selectCurrentLanguage,
  selectCurrentUserStatus,
  useAppSelector,
  useSetUserLanguageMutation
} from 'mmfintech-backend-api'

import { LoginStatusEnum } from 'mmfintech-commons-types'

type LanguageMenuProps = {
  borderless?: boolean
  hamburger?: boolean
}

export const LanguageMenu = ({ borderless, hamburger }: LanguageMenuProps) => {
  const userStatus = useAppSelector(selectCurrentUserStatus)
  const selectedLanguage = useAppSelector(selectCurrentLanguage)
  const [setLanguage] = useSetUserLanguageMutation()

  const [filteredLanguages, setFilteredLanguages] = useState([])

  const dispatch = useAppDispatch()

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const doSelectLanguage = (languageCode: string): void => {
    if (languageCode !== selectedLanguage) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        setLanguage({
          language: languageCode
        })
      }
      dispatch(changeLanguage(languageCode))
      storeLanguage(languageCode)
    }
    hideDropDown()
    initializeLanguage(languageCode)
  }

  const getLanguageName = () => {
    const data = filteredLanguages.find(item => item.code === selectedLanguage)
    return data ? data.name : ''
  }

  useEffect(() => {
    const filtered = settings.languages.map(code => languages.find(v => v.code === code)).filter(v => v != null)
    setFilteredLanguages(filtered)
    // eslint-disable-next-line
  }, [])

  if (!selectedLanguage) {
    return null
  }

  return (
    <MenuWrapper className={cn({ borderless, hamburger })} data-test='language-menu-wrapper'>
      <MenuContainer className={cn({ opened: visible })} onClick={toggleDropDown}>
        <span className='selected'>{getLanguageName()}</span>
        <span className='arrow'>{visible ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
      </MenuContainer>
      <MenuDropDown className={cn({ opened: visible })} data-test='language-menu-content'>
        <ul data-test='language-menu-list'>
          {filteredLanguages?.map(v => (
            <li key={v.code} onClick={() => doSelectLanguage(v.code)} data-test='language-menu-item'>
              {v.name}
            </li>
          ))}
        </ul>
      </MenuDropDown>
    </MenuWrapper>
  )
}
