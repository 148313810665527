import styled from 'styled-components'

export const HeaderIconsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;
  margin: 0 1.5rem;

  svg {
    cursor: pointer;
  }
`
