import styled from 'styled-components'

export const TransactionsSectionWrapper = styled.div``

export const TransactionsSectionTitle = styled.div`
  color: #85a0ad;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;
  margin-bottom: 2rem;
`

export const TransactionsWrapper = styled.div`
  font-family: 'Roboto-Condensed', sans-serif;
`

export const TransactionsTableContainer = styled.div`
  .details-cell {
    margin: 0 auto;

    .details-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;
      cursor: pointer;
    }
  }

  @media (min-width: 1200px) {
    .details-cell {
      width: 4.5rem;

      .details-text {
        display: none;
      }

      .details-button {
        justify-content: center;
        width: auto;
      }
    }
  }

  .data-table-head {
    .data-table-row {
      background-color: #f4f5f6;
      border-bottom: 1px solid rgba(163, 184, 194, 0.3);
      .data-table-head-cell {
        height: 3rem;
        vertical-align: middle;
        border-right: 1px solid rgba(163, 184, 194, 0.3);

        color: #85a0ad;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.4rem;
        text-transform: none;
      }
    }
  }

  .data-table-body {
    .data-table-row {
      box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
      cursor: pointer;
      :last-child {
        border-bottom: none;
      }

      .pagination-cell {
        margin: auto;
      }

      .data-table-cell {
        color: #454a54;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4rem;
        &.text-upper {
          text-transform: uppercase;
        }
        &.status-failed {
          color: #d13737;
        }
        &.status-cancelled {
          color: #e4c41e;
        }
        &.status-processed {
          color: #34a518;
        }
      }
      .data-table-cell-caption {
        text-transform: none;
      }
    }
  }

  .data-table-cell,
  .data-table-head-cell {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 1000px) {
    .data-table-container {
      background: transparent;
      .data-table-row {
        box-shadow: none;
        border-bottom: 1px solid #f4f6f6;
        .data-table-cell {
          border-color: #e6e6e6;
          border-style: solid;
          border-width: 1px 1px 0 0;
        }
      }
      /* border-radius: 8px; */
    }

    .data-table {
      .data-table-head {
        .data-table-row {
          .data-table-head-cell {
            padding-left: 1rem;
            :first-of-type {
              padding-left: 3rem;
              border-radius: 8px 0 0 0;
            }
            :last-of-type {
              border-radius: 0 8px 0 0;
            }
          }
        }
      }
    }

    .data-table-body {
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      .data-table-row {
        &:last-of-type {
          .data-table-cell {
            &:first-of-type {
              border-radius: 8px 0 0 0;
            }
            &:last-of-type {
              border-radius: 0 8px 0 0;
            }
          }
        }
        .data-table-cell {
          height: 5rem;
          &:first-of-type {
            padding-left: 3rem;
          }
          &:last-of-type {
            padding-right: 2rem;
          }
        }

        :last-of-type {
          .data-table-cell {
            :first-of-type {
              border-radius: 0 0 0 8px;
            }
            :last-of-type {
              border-radius: 0 0 8px 0;
            }
          }
        }
      }
    }
  }
`
