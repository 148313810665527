import cn from 'classnames'

import { CurrencyIconWrapper } from './CurrencyIcon.styled'

type CurrencyIconProps = {
  currency?: string
  width?: string
  height?: string
  circle?: boolean
  className?: string
}

export const CurrencyIcon = ({
  currency,
  width = '3rem',
  height = '3rem',
  circle = false,
  className = ''
}: CurrencyIconProps) => {
  return (
    <CurrencyIconWrapper
      width={width}
      height={height}
      className={cn(className, {
        circle,
        [currency?.replace(/\./g, '-')]: true
      })}
    />
  )
}
