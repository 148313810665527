import { useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { HeaderIconsWrapper } from './HeaderIcons.styled'

import { paths } from 'mmfintech-backend-api'

import { ReactComponent as ProfileIcon } from '../../../images/icons/profile-icon.svg'

export const HeaderIcons = () => {
  const history = useHistory()
  const menuRef = useRef<HTMLInputElement>(null)

  const handleProfileRedirect = () => {
    history.push(paths.security())
  }

  return (
    <HeaderIconsWrapper ref={menuRef}>
      <ProfileIcon data-test='profile-icon' onClick={() => handleProfileRedirect()} />
    </HeaderIconsWrapper>
  )
}
