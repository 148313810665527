import styled from 'styled-components'

export const ManageCookiesWrapper = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  padding: 4rem;
  max-width: 95rem;
  width: 100%;

  display: flex;
  flex-direction: column;

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;

  .inner {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .inner-container {
      padding: 0 2rem;
      &:last-of-type {
        border-top: 1px solid #f4f5f6;
        @media (min-width: 767px) {
          border-top: none;
          border-left: 1px solid #f4f5f6;
        }
      }
    }
    .header-container {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      margin: 1.5rem 0;
      h2 {
        color: #85a0ad;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.3rem;
        margin: 0;
      }
    }
  }
`

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  .button {
    max-width: 40rem;
  }
`
