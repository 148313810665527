import cn from 'classnames'

import { LogoWrapper } from './TomopayLogo.styled'

import settings from '../../settings'
import { isValidString } from 'mmfintech-commons'

import LogoImage from '../../images/icons/tomopay-logo.svg'

type TomopayLogoProps = {
  autoWidth?: boolean
  url?: string
}

export const TomopayLogo = ({ autoWidth, url = '' }: TomopayLogoProps) => (
  <LogoWrapper className={cn({ 'auto-width': autoWidth })}>
    <a href={isValidString(url) ? url : settings.landingPageUrl}>
      <img src={LogoImage} alt='Logo' />
    </a>
  </LogoWrapper>
)
