import { toast, Toaster, ToastBar } from 'react-hot-toast'

import { CloseIcon } from '../../icons'
import { CustomToasterStyled } from './CustomToaster.styled'

import { tr } from 'mmfintech-commons'

export const CustomToaster = () => (
  <Toaster
    gutter={10}
    position='top-right'
    containerStyle={{
      top: '10rem',
      zIndex: '10010'
    }}>
    {t => (
      <ToastBar
        toast={t}
        style={{
          display: 'block',
          backgroundColor: '#4B4B4B',
          borderRadius: '4px',
          color: '#ffffff',
          fontFamily: 'inherit',
          fontSize: '1.2rem',
          lineHeight: ' 2rem',
          fontStyle: 'normal',
          padding: '1.2rem',
          width: '100%',
          maxWidth: '320px'
        }}>
        {({ icon, message }) => (
          <CustomToasterStyled>
            <div className='icon'>{icon}</div>
            <div className='content'>
              <span className={`label ${t.type}`}>
                {t.type === 'success'
                  ? tr('FRONTEND.TOAST.SUCCESS', 'Successful')
                  : tr('FRONTEND.TOAST.ERROR', 'Error')}
              </span>
              <span>{message}</span>
            </div>
            {t.type !== 'loading' && (
              <span className='close-icon' onClick={() => toast.dismiss(t.id)}>
                <CloseIcon />
              </span>
            )}
          </CustomToasterStyled>
        )}
      </ToastBar>
    )}
  </Toaster>
)
