// noinspection JSCheckFunctionSignatures

import React from 'react'

import Login from './views/auth/Login'
import Dashboard from './views/dashboard'

const LazySignup = React.lazy(() => import('./views/auth/Signup'))
const LazySumSub = React.lazy(() => import('./views/onboarding/SumSubVerification'))
const LazyVerifyEmail = React.lazy(() => import('./views/auth/VerifyEmail'))

import { paths } from 'mmfintech-backend-api'

// prettier-ignore
export const nonProtectedRoutes = [
  { path: paths.login(), component: Login },
  { path: paths.invite(':link'), component: React.lazy(() => import('./views/auth/Invite')) },
  { path: paths.signUp(), component: LazySignup },
  { path: paths.checkEmail(), component: React.lazy(() => import('./views/auth/VerifyEmailMessage')) },
  { path: paths.passwordReset(), component: React.lazy(() => import('./views/auth/ResetPasswordMessage')) },
  { path: paths.forgotPassword(), component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.handleEmailVerify(), component: LazyVerifyEmail },
  { path: paths.changePasswordEmail(), component: React.lazy(() => import('./views/auth/ForgotPasswordMessage')) },
  { path: paths.handlePasswordReset(), component: React.lazy(() => import('./views/auth/ResetPassword')) },
  { path: paths.signUpViaInviteLink(':referralCode'), component: LazySignup },
  { path: paths.handleInvitationVerify(), component: LazyVerifyEmail },
  { path: paths.handlePasswordResetInvited(), component: React.lazy(() => import('./views/auth/SetPassword')) },
]

// prettier-ignore
export const protectedRoutes = [
  { path: paths.root(), exact: true, redirect: paths.login() },

  { path: paths.dashboard(), component: Dashboard },
  { path: paths.security(), component: React.lazy(() => import('./views/settings/Security')) },

  { path: paths.payout.crypto(), exact: true, component: React.lazy(() => import('./views/banking/Payout/CryptoTransfer')) },
  { path: paths.payout.select(), component: React.lazy(() => import('./views/banking/Payout/Selection')) },

  { path: paths.withdraw.crypto(), exact: true, component: React.lazy(() => import('./views/banking/Withdrawal/CryptoTransfer')) },
  { path: paths.withdraw.select(), component: React.lazy(() => import('./views/banking/Withdrawal/Selection')) },

  { path: paths.banking.transactions.details(':transactionId'), component: React.lazy(() => import('./views/transactions/TransactionDetails')) },
  { path: paths.banking.exchange(), component: React.lazy(() => import('./views/banking/Exchange')) },

  { path: paths.developer(), component: React.lazy(() => import('./views/developer')) },
  { path: paths.userManagement(), component: React.lazy(() => import('./views/users')) },

  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, component: LazySumSub },
  { path: paths.onboarding.sumSub.continue(), exact: true, component: LazySumSub },

  { path: paths.error404(), component: React.lazy(() => import('./views/auth/Error404')) },
  { path: '*', redirect: paths.error404() }
]
