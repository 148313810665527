import styled from 'styled-components'

export const MenuWrapper = styled.div`
  position: relative;

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.6rem;

  border: 1px solid #d2d9e0;
  border-radius: 8px;

  &.hamburger {
    align-self: center;
    width: 15rem;
    padding: 1.6rem 0;
  }
  &.borderless {
    border: none;
  }
`

export const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;

  min-width: 12rem;
  height: 3.6rem;

  .arrow {
    width: 11px;
    margin: 0 1.2rem 0 1rem;
  }
`

export const MenuDropDown = styled.div`
  z-index: 1001;
  position: absolute;
  top: 4.5rem;
  right: 0;

  width: 16rem;
  padding: 1rem 0;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  display: none;
  &.opened {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 4rem;
      padding: 0 1rem;
      color: #454a54;

      :hover {
        background: #e7ebee;
      }
      .icon {
        display: flex;
        margin: 0 1rem;
      }
    }
  }
`
