import { isValidString } from 'mmfintech-commons'
import { useGetMerchantQuery, useGetSumSubAccessTokenMutation, useOnboardingBannerQry } from 'mmfintech-backend-api'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

export const useOnboarding = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { capabilities, entityType, accountType, onboardingStatus } = merchant || {}
  const { ibanEligible } = capabilities || {}

  const [fetchAccessToken] = useGetSumSubAccessTokenMutation()

  const startOnboarding = async () => {
    if (isValidString(entityType)) {
      if (accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.NOT_STARTED) {
        try {
          const response = await fetchAccessToken(entityType.toLowerCase()).unwrap()

          if (response) {
            await startOrContinueOnboarding(response?.onboardingStatus)
          }
        } catch (err) {}
      }
    }
  }

  const { startOrContinueOnboarding } = useOnboardingBannerQry({
    startOnboarding
  })

  return {
    startOrContinueOnboarding,
    shouldRequestIban: ibanEligible
  }
}
