import { useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

import { HeaderIcons } from './HeaderIcons'
import { LanguageMenu } from '../LanguageMenu'
import { HamburgerMenu } from './HamburgerMenu'
import {
  BrandLogoWrapper,
  Hamburger,
  HamburgerIcon,
  HeaderContainer,
  HeaderInnerContainer,
  HeaderWrapper
} from './Header.styled'

import settings from '../../settings'

import { tr, useWindowSize } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useAuth,
  useGetMerchantQuery
} from 'mmfintech-backend-api'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import LogoImage from '../../images/icons/tomopay-logo.svg'

const MAX_MOBILE_WIDTH = 1176

export const Header = () => {
  const [opened, setOpened] = useState(false)
  const [visible, setVisible] = useState(false)

  const userStatus = useAuth()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { data: merchant } = useGetMerchantQuery()
  const { apiEnabled } = merchant || {}

  const location = useLocation()

  const [width] = useWindowSize()

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN
  const isHamburgerVisible = () => width < MAX_MOBILE_WIDTH
  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator(customerRole)

  const hideMenu = () => opened && setOpened(false)

  const BrandLogoImage = () => <img src={LogoImage} alt='Logo' style={{ maxHeight: '6rem' }} />

  const BrandLogo = () => {
    return (
      <BrandLogoWrapper>
        {isLoggedIn() ? (
          <Link className='brand-logo' to={paths.dashboard()}>
            <BrandLogoImage />
          </Link>
        ) : (
          <a href={settings.landingPageUrl}>
            <BrandLogoImage />
          </a>
        )}
      </BrandLogoWrapper>
    )
  }

  const MainMenuItems = () => (
    <>
      <NavLink activeClassName='active-tab' to={paths.dashboard()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.OVERVIEW', 'Overview')}
      </NavLink>
      {isHamburgerVisible() && (
        <NavLink activeClassName='active-tab' to={paths.security()} onClick={hideMenu}>
          {tr('FRONTEND.PROFILE.TITLE', 'Profile')}
        </NavLink>
      )}
      {isDevelopersMenuVisible() && (
        <NavLink activeClassName='active-tab' to={paths.developer()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.DEVELOPERS', 'Developers')}
        </NavLink>
      )}
      {isOwnerOrAdministrator(customerRole) && (
        <NavLink activeClassName='active-tab' to={paths.userManagement()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.USERS', 'Users')}
        </NavLink>
      )}
    </>
  )

  useEffect(() => {
    width > MAX_MOBILE_WIDTH && hideMenu()
    // eslint-disable-next-line
  }, [width])

  useEffect(() => {
    const pathname = location.pathname
    // noinspection DuplicatedCode
    const state =
      pathname !== paths.login() &&
      pathname !== paths.signUp() &&
      pathname !== paths.checkEmail() &&
      pathname !== paths.passwordReset() &&
      pathname !== paths.forgotPassword() &&
      pathname !== paths.changePasswordEmail() &&
      pathname !== paths.handlePasswordReset() &&
      pathname !== paths.handlePasswordResetInvited()
    if (state !== visible) {
      setVisible(state)
    }
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (opened) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [opened])

  return (
    <HeaderWrapper visible={visible}>
      {opened && <div className='overlay' onClick={hideMenu} />}

      <HeaderContainer maxMobileWidth={MAX_MOBILE_WIDTH}>
        <BrandLogo />

        {isHamburgerVisible() ? (
          <HeaderInnerContainer>
            <Hamburger>
              <HamburgerIcon onClick={() => setOpened(prevState => !prevState)}>
                <span className='menu-icon' />
              </HamburgerIcon>

              <HamburgerMenu
                opened={opened}
                maxWidth={MAX_MOBILE_WIDTH}
                hideMenu={() => setOpened(false)}
                Items={MainMenuItems}
              />
            </Hamburger>
          </HeaderInnerContainer>
        ) : (
          <HeaderInnerContainer>
            {isLoggedIn() && (
              <>
                <MainMenuItems />
                <HeaderIcons />
                <LanguageMenu hamburger={false} />
              </>
            )}
          </HeaderInnerContainer>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  )
}
