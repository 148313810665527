import styled from 'styled-components'

import { CommonSignupContainer, CommonSignupHeader, CommonSignupWrapper } from '../../../components'

export const LoginWrapper = styled(CommonSignupWrapper)`
  padding: 3rem 0 10rem;
`

export const LoginHeader = styled(CommonSignupHeader)``

export const LoginContainer = styled(CommonSignupContainer)``
