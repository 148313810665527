import { useContext, useState } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { StatusSwitch } from '../StatusSwitch'
import { ButtonsContainer, ManageCookiesWrapper, SwitchContainer } from './ManageCookies.styled'

import settings from '../../settings'
import { tr, GlobalContext } from 'mmfintech-commons'

export const ManageCookies = ({ setCookie }) => {
  const { modalHide } = useContext(GlobalContext)

  const [optionalCookies, setOptionalCookies] = useState(true)

  const onSaveButtonClick = () => {
    setCookie(
      'cookie.consent',
      JSON.stringify({
        necessaryCookies: true,
        optionalCookies
      }),
      {
        days: 365,
        maxAge: 31536000,
        domain: settings.cookieDomain
      }
    )
    modalHide()
  }

  return (
    <ManageCookiesWrapper data-test='manage-cookies-modal'>
      <div className='inner'>
        <div className='inner-container'>
          <div className='header-container'>
            <h2 className='title'>{tr('FRONTEND.COOKIE_CONSENT.NEED_COOKIES_TITLE', 'Necessary Cookies')}</h2>
            <StatusSwitch defaultChecked={false} disabled />
          </div>
          <SwitchContainer>
            <p>
              {tr(
                'FRONTEND.COOKIE_CONSENT.NEED_COOKIES',
                'We need to use these cookies to make our website work. These are all necessary for us to function and cannot be switched off in our systems. They are for setting responses to actions made by you, such as setting your privacy preferences, logging in or filling forms.'
              )}
            </p>
          </SwitchContainer>
        </div>
        <div className='inner-container'>
          <div className='header-container'>
            <h2>{tr('FRONTEND.COOKIE_CONSENT.ST_COOKIE_POLICY', 'Tomopay Cookies Policy')}</h2>
            <StatusSwitch enabled={optionalCookies} onChange={() => setOptionalCookies(prevState => !prevState)} />
          </div>

          <SwitchContainer>
            <p>
              {tr(
                'FRONTEND.COOKIE_CONSENT.COUNT_VISITS',
                'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. All information that our cookies collect is aggregated and anonymous.'
              )}
            </p>
            <p>
              {tr(
                'FRONTEND.COOKIE_CONSENT.SEE_PAGES_AND_LINKS',
                "These also allow us to see the pages and links you have visited so we can prevent relevant ads. We don't store directly personal information but identify your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising."
              )}
            </p>
          </SwitchContainer>
        </div>
      </div>

      <ButtonsContainer>
        <Button
          color='primary'
          text={tr('FRONTEND.COOKIE_CONSENT.SAVE', 'Save')}
          onClick={onSaveButtonClick}
          data-test='button-save'
        />
      </ButtonsContainer>
    </ManageCookiesWrapper>
  )
}
