import styled from 'styled-components'

export const CustomToasterStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .icon {
    padding: 0 1rem 0 0;
  }

  .content {
    border-left: 1px solid rgba(163, 184, 194, 0.3);
    flex: 1;
    padding: 0 1rem;
    display: flex;
    flex-flow: column nowrap;

    span > div {
      margin: 0;
      justify-content: initial;
    }

    .label {
      font-size: 1.4rem;
      line-height: 2rem;

      &.success {
        color: #44cc00;
      }

      &.error {
        color: #ff3131;
      }
    }
  }

  .close-icon {
    cursor: pointer;
  }
`
