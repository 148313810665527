export const NoTransactionsIcon = ({ color = '#15cf80' }) => (
  <svg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M32.5002 21.1661V8.25049C32.5002 5.48906 30.2616 3.25049 27.5002 3.25049H9.87515C7.11373 3.25049 4.87515 5.48906 4.87515 8.25049V29.938C4.87515 32.6994 7.11373 34.938 9.87515 34.938H18.7283'
      stroke={color}
      strokeWidth='1.5'
      strokeMiterlimit='10'
    />
    <path d='M30 32L25 27' stroke={color} strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M30 27L25 32' stroke={color} strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
    <path
      d='M27.4375 37.875C32.0974 37.875 35.875 34.0974 35.875 29.4375C35.875 24.7776 32.0974 21 27.4375 21C22.7776 21 19 24.7776 19 29.4375C19 34.0974 22.7776 37.875 27.4375 37.875Z'
      stroke={color}
      strokeWidth='1.5'
      strokeMiterlimit='10'
    />
    <path d='M10 8L27 8' stroke={color} strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M10 13H27' stroke={color} strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M10 18L18 18' stroke={color} strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
  </svg>
)
