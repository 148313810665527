export const CloseIcon = ({ color = '#a3b8c2' }) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.00001 7.06116L10.045 11.1062L11.1057 10.0455L7.06067 6.0005L11.1057 1.9555L10.045 0.894844L6.00001 4.93984L1.9547 0.894531L0.894043 1.95519L4.93935 6.0005L0.894043 10.0458L1.9547 11.1065L6.00001 7.06116Z'
      fill={color}
    />
  </svg>
)
