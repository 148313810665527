import styled from 'styled-components'
import { CommonSignupContent } from '../Commons'

export const SignupContent = styled(CommonSignupContent)`
  margin-bottom: 0;
`

export const StyledForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  .area-code-button:focus .selected-flag,
  .area-code-button:hover .selected-flag {
    background: transparent;
  }

  .password-terms {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
`
