import styled from 'styled-components'

import IconClose from '../../../../images/icons/icon-close.svg'
import IconCloseHover from '../../../../images/icons/icon-close-hover.svg'

export const CloseIconWrap = styled.span`
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 2rem;

  width: 4rem;
  height: 4rem;
  background: url(${IconClose}) center center no-repeat;

  :hover {
    background: url(${IconCloseHover}) center center no-repeat;
  }
`
