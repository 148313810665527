import { ErrorFallbackIcon } from '../../icons'
import { AlertContent, AlertWrapper, TryAgainButton } from './ErrorFallback.styled'

export const ErrorFallback = ({ resetErrorBoundary }) => (
  <AlertWrapper>
    <AlertContent>
      <ErrorFallbackIcon />

      <p>An unexpected error occurred</p>

      <TryAgainButton type='button' onClick={resetErrorBoundary}>
        Go back to the home page
      </TryAgainButton>
    </AlertContent>
  </AlertWrapper>
)
