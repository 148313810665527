import { StatusSwitchStyled } from './StatusSwitch.styled'

type StatusSwitchProps = {
  enabled?: boolean
  onChange?: () => void
  dataTest?: string
  disabled?: boolean
  defaultChecked?: boolean
  className?: string
}

export const StatusSwitch = ({
  enabled,
  onChange,
  dataTest,
  disabled,
  defaultChecked,
  className
}: StatusSwitchProps) => (
  <StatusSwitchStyled className={className} data-test={dataTest}>
    <input type='checkbox' checked={enabled} disabled={disabled} defaultChecked={defaultChecked} onChange={onChange} />
    <span />
  </StatusSwitchStyled>
)
