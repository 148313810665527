export const PayoutIcon = ({ color = '#15cf80', stroke = 'white' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
    <circle cx='15' cy='15' r='15' fill={color} />
    <path
      d='M22 13.2295V12.3566C22 10.9057 20.8238 9.72949 19.3729 9.72949H10.6271C9.1762 9.72949 8 10.9057 8 12.3566V17.6024C8 19.0533 9.1762 20.2295 10.6271 20.2295H15.4375'
      stroke={stroke}
      strokeLinecap='round'
    />
    <path d='M9.75012 17.2175H10.5001' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13.2493 17.1831L21.4774 17.1831' stroke={stroke} strokeLinecap='round' />
    <path
      d='M19.0418 14.1042L21.8262 17.198L19.0418 20.2918'
      stroke={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
