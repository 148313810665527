import React, { useContext } from 'react'

import { LanguageMenu, LoginForm, TomopayLogo } from '../../../components'
import { LoginContainer, LoginHeader, LoginWrapper } from './Login.styled'

import { useAppDispatch } from '../../../hooks'
import { OtpContext, useFormValues } from 'mmfintech-commons'
import { loggedIn, useLoginReqMutation } from 'mmfintech-backend-api'

const Login = () => {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const [login, { error: loginError, isLoading: loginFetching, reset: resetLoginReq }] = useLoginReqMutation()

  const dispatch = useAppDispatch()

  const formValues = useFormValues({
    email: { required: true, validation: 'email' },
    password: { required: true }
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOtpOnSuccess(() => (response: any) => {
      dispatch(loggedIn(response))
    })
    if (formValues.areValid()) {
      try {
        await login(formValues.prepare()).unwrap()
      } catch (error) {}
    }
  }

  const handleResetError = () => {
    if (loginError) {
      resetLoginReq()
    }
  }

  return (
    <LoginWrapper>
      <LoginHeader>
        <TomopayLogo autoWidth />
        <div className='language-selection'>
          <LanguageMenu />
        </div>
      </LoginHeader>
      <LoginContainer>
        <LoginForm
          formValues={formValues}
          handleSubmit={handleSubmit}
          loading={loginFetching}
          error={loginError}
          onReset={handleResetError}
        />
        <span />
      </LoginContainer>
    </LoginWrapper>
  )
}

export default Login
