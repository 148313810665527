import styled from 'styled-components'

export const DashboardStyled = styled.div`
  grid-area: main-content;
  padding: 3rem 2rem;

  .no-elements {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 7px;

    color: #454a54;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`

export const TransactionsContainer = styled.div`
  .active-button {
    border: 1px solid #15cf80;
  }

  .lower-section {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 700px) {
      flex-flow: row nowrap;
    }
  }
`

export const TransactionsExportButtons = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  .button {
    background-color: rgba(21, 207, 128, 0.1);
    border-radius: 8px;
    margin-bottom: 1rem;
    max-width: 30rem;
    width: 100%;
    color: #454a54;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4rem;

    &:focus,
    &:hover {
      background-color: rgba(21, 207, 128, 0.1);
      color: #454a54;
    }
  }
  @media (min-width: 480px) {
    .button {
      width: auto;
      margin-bottom: 0;
    }
  }
`
