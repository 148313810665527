import { registerLocale } from 'react-datepicker'
import { configuration, initialize } from 'mmfintech-backend-api'

import ja from 'date-fns/locale/ja'

initialize(import.meta.env)
registerLocale('ja', ja)

const settings = {
  backendForLocalhost: 'https://portal-dev.tomopay.net',
  // backendForLocalhost: 'https://portal-sandbox.tomopay.net',

  cookieDomain: configuration.isLocal() ? null : '.tomopay.net',
  customerServiceUrl: 'mailto:support@tomopay.net',
  landingPageUrl: configuration.isDevelopment()
    ? 'https://dev.tomopay.net/'
    : configuration.isSandbox()
    ? 'https://sandbox.tomopay.net/'
    : 'https://tomopay.net',
  languages: ['en', 'ja'],

  defaultUploadInvoiceAcceptType: ['pdf', 'jpg', 'jpeg', 'png'],
  defaultUploadInvoiceMaxFileSize: 1024 * 1024,
  logoAcceptType: ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  logoMaxFileSize: 1024 * 1024,
  questionnaireFileTypes: ['pdf', 'jpg', 'jpeg', 'png'],
  questionnaireMaxFileSize: 1024 * 1024 * 10
}

export default settings
