import styled from 'styled-components'

export const VerificationContainer = styled.div`
  width: 100%;
  max-width: 50rem;

  margin: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  .inner {
    padding: 3rem 2rem;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 2rem;

    @media (min-width: 360px) {
      padding: 2rem 3rem;
    }
  }

  .err-msg-wrapper {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .title {
    color: #000000;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.9rem;
    text-align: center;
  }

  .subtitle {
    color: #000000;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;

    text-align: center;

    span {
      display: block;
      font-weight: 700;
      overflow-wrap: anywhere;
    }
  }
  .smart-id-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .input {
    color: #000000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.2rem;
    letter-spacing: 1.2rem;

    text-align: center;

    width: 20rem;
    padding: 1rem;
    margin: 0 auto;

    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: #85a0ad;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6rem;
      letter-spacing: normal;
    }
    &:-ms-input-placeholder {
      color: #85a0ad;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6rem;
      letter-spacing: normal;
    }
    &::placeholder {
      color: #85a0ad;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6rem;
      letter-spacing: normal;
    }

    &.wrong {
      border-color: transparent;
    }
  }
  .buttons-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .button {
      margin-top: 1rem;
    }
  }
`

export const Input = styled.input``

export const ResendMessage = styled.div`
  background-color: #fafafa;
  padding: 2rem 0;
  color: #000000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7rem;
  text-align: center;

  border-radius: 0 0 8px 8px;

  span {
    margin-top: 0.5rem;
    display: block;
    cursor: pointer;
    color: #2f80ed;

    &.disabled {
      color: #545454;
      cursor: default;
      text-decoration: none;
    }
  }
`

export const Countdown = styled.div<{ isExpired: boolean }>`
  color: ${p => (p.isExpired ? '#ee2b2b' : '#85a0ad')};
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem;
`
