import styled from 'styled-components'

export const AdvancedFiltersWrapper = styled.div`
  grid-area: main-content;
  margin-bottom: 1rem;

  text-align: center;

  @media (max-width: 1000px) {
    padding: 2rem 3rem;
  }
  .title-container {
    margin: 3rem 0 2rem;
    position: relative;
    .back-button-wrapper {
      position: absolute;
      top: 0;
    }

    .title {
      color: #454a54;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: center;
      width: 100%;
    }
  }
  .inner {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;

    @media (max-width: 1000px) {
      display: flex;
      flex-flow: row wrap;
    }
  }

  .input-wrapper {
    & > div {
      border-radius: 8px;
      border: 1px solid #e1e6ea;
      padding: 0;
    }

    label {
      color: #454a54;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.4rem;
      margin-bottom: 0.5rem;
      text-transform: none;
    }

    input {
      height: 3.5rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-weight: 400;
      padding: 0 1rem;

      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }

  [data-test='number-input-id'] {
    max-width: 7rem;
  }
  [data-test='text-input-foreignTransactionId'],
  [data-test='text-input-payerName'],
  [data-test='text-input-payerEmail'] {
    max-width: 17rem;
  }
  [data-test='text-input-reference'] {
    max-width: 12rem;
  }
  [data-test='text-input-customerEmail'],
  [data-test='text-input-description'] {
    max-width: 22rem;
  }
  [data-test='text-input-toAmount'],
  [data-test='text-input-fromAmount'] {
    max-width: 11rem;
  }
  [data-test='select-input-statuses'] {
    max-width: 11rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
  [data-test='select-input-currency'] {
    max-width: 10rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
  [data-test='select-input-paymentMethods'] {
    width: auto;
    min-width: 20rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 1000px) {
    [data-test='number-input-id'],
    [data-test='text-input-foreignTransactionId'],
    [data-test='text-input-payerName'],
    [data-test='text-input-payerEmail'],
    [data-test='text-input-reference'],
    [data-test='text-input-customerEmail'],
    [data-test='text-input-description'],
    [data-test='text-input-toAmount'],
    [data-test='text-input-fromAmount'],
    [data-test='select-input-statuses'],
    [data-test='select-input-currency'],
    [data-test='select-input-paymentMethods'] {
      width: 100%;
      max-width: none;
    }
  }
`

export const FilterSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    fontFamily: 'inherit',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    background: '#ffffff',
    borderRadius: '8px'
  }),
  control: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    color: '#333333',
    background: '#ffffff',
    borderWidth: '1px',
    borderStyle: 'none',
    borderRadius: '8px',
    minHeight: '3.5rem',
    //paddingLeft: '1rem',
    padding: '0',
    transition: 'none',
    boxShadow: 'none',
    outline: 'none'
  }),
  multiValue: (provided: any) => ({
    ...provided,
    background: '#ffffff',
    borderRadius: '3px',
    color: '#333333'
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'inherit'
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'inherit'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: '#333333',
    padding: '0 8px'
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: '0 8px 0 0'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0 8px 0 0',
    color: '#333333'
  }),
  input: (provided: any) => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    lineHeight: '1.4rem'
  }),
  menu: (provided: any) => ({
    ...provided,
    background: '#ffffff',
    border: 'none !important',
    borderRadius: '1rem',
    margin: '1rem 0 0 0',
    padding: '1rem 0',
    boxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)',
    ':hover': {
      border: 'none !important'
    }
  }),
  option: (provided: any) => ({
    ...provided,
    color: '#000000',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    cursor: 'pointer',
    textAlign: 'left',
    background: '#ffffff',
    //marginTop: '5px',
    padding: '1rem 2rem',
    ':hover': {
      background: '#e7ebee'
    }
  })
}
