import styled from 'styled-components'

import HamburgerMenuIcon from '../../../images/icons/hamburger-menu.svg'

export const Hamburger = styled.div`
  padding-right: 1rem;
  @media (min-width: 320px) {
    padding-right: 1.5rem;
  }

  div.title {
    color: #000000;
  }

  a {
    margin-bottom: 0;
    padding: 1.6rem 2.5rem;

    &.active-tab {
      border-bottom: none;
    }
  }
`

export const HamburgerIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  padding: 0;
  z-index: 10;
  border: none;
  cursor: pointer;
  background: transparent;

  :focus {
    outline: none;
  }

  svg:hover > * {
    fill: #bdbdbd;
  }

  .menu-icon {
    width: 2.5rem;
    height: 2.5rem;
    background: url(${HamburgerMenuIcon}) center center no-repeat;
  }
`

export const HamburgerMenuWrapper = styled.nav<{ open: boolean; maxMobileWidth: number }>`
  padding: 5rem;

  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  background: #ffffff;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};

  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;

  .items-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    a,
    div.title {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.2rem;
      height: auto;
    }
  }
  .action-button {
    border-top: 1px solid #d3d9de;
    padding: 2rem 0;
  }
`
