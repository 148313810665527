import styled from 'styled-components'

export const CookieConsentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  z-index: 20;
  width: 95vw;
  max-width: 50rem;

  position: fixed;
  right: 1rem;
  bottom: 2rem;
  background-color: rgba(21, 207, 128, 0.1);
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  color: #454a54;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;

  @media (min-width: 600px) {
    right: 5rem;
    bottom: 4rem;
  }
`

export const ConsentContent = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 50px;
  padding: 3rem 3rem 2.5rem;

  .title {
    color: #15cf80;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.8rem;
    margin-bottom: 1rem;
  }
`

export const ConsentFooter = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  padding: 2rem 3rem;

  button {
    height: 5rem;
    width: 100%;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.9rem;

    border-radius: 1rem;
    box-shadow: none;
    outline: none;

    &.manage {
      height: 5rem;
      border: 1.5px solid #15cf80;
      border-radius: 8px;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }

  @media (min-width: 500px) {
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
`
