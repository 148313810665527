import { ArrowButton, Input, Button } from 'mmfintech-portal-commons'
import { AdvancedFiltersWrapper, FilterSelectStyles } from './AdvancedFilters.styled'

import { getTransactionStatusOptions, usePaymentMethods } from 'mmfintech-backend-api'
import { tr, useWindowSize, isValidArray, checkSingleValue } from 'mmfintech-commons'

export const AdvancedFilters = ({ stateFilter, onClose = undefined }) => {
  const { paymentMethods } = usePaymentMethods()

  const [width] = useWindowSize()

  const getPaymentMethodOptions = () => {
    if (isValidArray(paymentMethods)) {
      return paymentMethods
        .filter(method => ['LOCAL_BANKWIRE', 'BANKWIRE'].includes(method.name))
        .map(method => ({
          value: method.name,
          label: tr(`METADATA.PAYMENT_OPTIONS.${method.name}`, method.displayName)
        }))
    } else {
      return []
    }
  }

  const handleApplyFilters = () => {
    onClose()
  }

  return (
    <AdvancedFiltersWrapper>
      {width <= 1000 && (
        <div className='title-container'>
          <ArrowButton text={tr('FRONTEND.BUTTONS.BACK', 'Back')} onClick={onClose} />
          <div className='title'>Filters</div>
        </div>
      )}
      {stateFilter ? (
        <div className='inner'>
          <Input
            type='number'
            name='id'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TRANSACTION_ID', 'ID')}
            {...stateFilter.values.registerInput('id')}
            data-test='filter-id'
            hideErrorLine
            forceLabel
          />

          <Input
            type='text'
            name='foreignTransactionId'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.FOREIGN_ID', 'Foreign ID')}
            {...stateFilter.values.registerInput('foreignTransactionId')}
            data-test='filter-foreign-id'
            hideErrorLine
            forceLabel
          />

          <Input
            type='text'
            name='payerEmail'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.PAYER_EMAIL', 'Payer Email')}
            {...stateFilter.values.registerInput('payerEmail')}
            data-test='filter-payer-email'
            hideErrorLine
            forceLabel
          />

          <Input
            type='text'
            name='payerName'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.PAYER_NAME', 'Payer Name')}
            {...stateFilter.values.registerInput('payerName')}
            data-test='filter-payer-name'
            hideErrorLine
            forceLabel
          />

          <Input
            type='text'
            name='reference'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.REFERENCE', 'Reference')}
            {...stateFilter.values.registerInput('reference')}
            data-test='filter-reference'
            hideErrorLine
            forceLabel
          />

          <Input
            type='select'
            name='paymentMethods'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.PAYMENT_METHOD', 'Payment method')}
            {...stateFilter.values.registerInput('paymentMethods')}
            styles={FilterSelectStyles}
            options={getPaymentMethodOptions()}
            data-test='filter-payment-method'
            hideErrorLine
            isMultiSelect
            isSearchable={false}
            forceLabel
            onChange={(name: string, value: any) => {
              // const payload = value
              // if (localBankwire !== -1 && bankwire !== -1 && value.includes('BANKWIRE')) {
              //   payload.push('LOCAL_BANKWIRE')
              // }
              stateFilter.values.set(name, value)
              stateFilter.values.reload()
            }}
          />

          <Input
            type='text'
            name='fromAmount'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_AMOUNT', 'From Amount')}
            value={stateFilter.values.get('fromAmount')}
            onChange={(name: string, value: any) => {
              stateFilter.values.set(name, checkSingleValue(value, { validation: 'float' }))
              stateFilter.values.reload()
            }}
            data-test='filter-from-amount'
            hideErrorLine
            forceLabel
          />

          <Input
            type='text'
            name='toAmount'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_AMOUNT', 'To Amount')}
            value={stateFilter.values.get('toAmount')}
            onChange={(name: string, value: any) => {
              stateFilter.values.set(name, checkSingleValue(value, { validation: 'float' }))
              stateFilter.values.reload()
            }}
            data-test='filter-to-amount'
            hideErrorLine
            forceLabel
          />

          <Input
            type='select'
            name='statuses'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.STATUS', 'Status')}
            {...stateFilter.values.registerInput('statuses')}
            styles={FilterSelectStyles}
            options={[
              { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_STATUSES', 'All Statuses') },
              ...getTransactionStatusOptions()
            ]}
            data-test='filter-status'
            hideErrorLine
            isSearchable={false}
            forceLabel
          />
          {width <= 1000 && <Button color='primary' text='Apply filters' onClick={handleApplyFilters} />}
        </div>
      ) : null}
    </AdvancedFiltersWrapper>
  )
}
