export const WithdrawIcon = ({ color = '#15cf80', stroke = 'white' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
    <circle cx='15' cy='15' r='15' fill={color} />
    <path
      d='M19.4345 10.5012H20.0622C20.9401 10.5012 21.6517 11.2129 21.6517 12.0908V19.3644C21.6517 20.2423 20.9401 20.954 20.0622 20.954H9.9378C9.05993 20.954 8.34827 20.2423 8.34827 19.3644V12.0908C8.34827 11.2129 9.05992 10.5012 9.9378 10.5012H10.5655'
      stroke={stroke}
      strokeLinecap='round'
    />
    <path
      d='M18.758 13.9541L15.0002 17.7119L11.2424 13.9541'
      stroke={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M14.9998 17.7632L14.9998 6.95411' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
