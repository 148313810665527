import { ReactNode } from 'react'

import { ArrowRightIcon, BellIcon } from '../../../icons'
import { BannerContent, BannerTitle, BannerWrapper } from './SumSubBanner.styled'

import { isValidFunction, isValidString } from 'mmfintech-commons'

type BannerProps = {
  title?: string
  content: ReactNode
  handleClick?: () => void
  className?: string
}

export const Banner = ({ title, content, handleClick, className }: BannerProps) => {
  const clickable = isValidFunction(handleClick)

  return (
    <BannerWrapper className={className} onClick={() => (clickable ? handleClick() : null)}>
      <div className='left'>
        <BellIcon />
        <BannerContent>
          {isValidString(title) && <BannerTitle>{title}</BannerTitle>}
          {content}
        </BannerContent>
      </div>
      {clickable && (
        <span onClick={handleClick}>
          <ArrowRightIcon />
        </span>
      )}
    </BannerWrapper>
  )
}
