import styled from 'styled-components'

export const AccessDeniedContainer = styled.div`
  grid-area: main-content;

  background: #ffffff;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  border-radius: 8px;

  max-height: 23rem;
  max-width: 35rem;

  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  @media (min-width: 500px) {
    max-width: 50rem;
  }

  .text-container {
    margin: 0 auto;
    text-align: center;

    padding: 0 2rem 3rem;

    .title {
      font-weight: 400;
      font-size: 2.4rem;
    }

    .headline {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2.2rem;
    }

    .button-back {
      max-width: 40rem;
      margin-top: 2rem;

      font-size: 1.8rem;
    }
  }
`
