import styled from 'styled-components'

import { CommonSignupContent } from '../Commons'

export const LoginContent = styled(CommonSignupContent)``

export const LoginContentWrapper = styled.div`
  width: 100%;
`

export const SignupLinkWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: #939ea7;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 102.52%;
  letter-spacing: -0.025em;

  position: relative;

  a {
    color: rgba(47, 128, 237, 1);
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6rem;

    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.2rem;
  }
`

export const StyledForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  .input-wrapper,
  .button {
    width: 100%;
  }
  .back-button {
    margin-top: 2rem;
  }

  .link-wrap {
    text-align: right;

    a {
      color: #2f80ed;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.4rem;
      text-decoration: none;
      outline: none;
    }
  }
`
