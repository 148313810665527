import styled from 'styled-components'

export const StatusBannerStyled = styled.div`
  &.status-box {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: center;
    padding: 0.7rem 1rem;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 1rem;

    max-width: 12rem;

    &::before {
      content: ' ';
      display: inline-block;
      width: 6px;
      height: 6px;
      text-align: center;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
    }

    &.pending,
    &.scheduled,
    &.in-progress,
    &.initial,
    &.buy {
      color: #ffce32;
      &::before {
        background-color: #ffce32;
      }
    }

    &.processed,
    &.onboarded,
    &.active,
    &.paid,
    &.sell {
      color: #15cf80;
      &::before {
        background-color: #15cf80;
      }
    }

    &.failed,
    &.cancelled,
    &.rejected,
    &.pending-cancellation {
      color: #ff3131;
      &::before {
        background-color: #ff3131;
      }
    }

    &.not-verified,
    &.not-started,
    &.under-review {
      color: #85a0ad;
      &::before {
        background-color: #85a0ad;
      }
    }
  }
`
