import { Link } from 'react-router-dom'

import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { LoginContent, LoginContentWrapper, SignupLinkWrapper, StyledForm } from './LoginForm.styled'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

export const LoginForm = ({ formValues, handleSubmit, loading, error, onReset }) => {
  return (
    <LoginContentWrapper>
      <LoginContent>
        <StyledForm noValidate onSubmit={handleSubmit}>
          <Input
            id='email'
            type='email'
            data-test='email'
            label={tr('FRONTEND.LOGIN.EMAIL', 'Email')}
            {...formValues?.registerInput('email', onReset)}
            disabled={loading}
            hideErrorLine
            autoFocus
          />

          <div className='link-wrap' data-test='forgot-password'>
            <Link to={paths.forgotPassword()}>{tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}</Link>
          </div>

          <Input
            id='password'
            type='password'
            data-test='password'
            label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
            {...formValues?.registerInput('password', onReset)}
            disabled={loading}
            autoComplete='off'
            hideErrorLine
          />

          <ErrorDisplay error={[error]} />

          <Button
            type='submit'
            color='primary'
            text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
            loading={loading}
            data-test='button-submit'
          />
        </StyledForm>

        <SignupLinkWrapper>
          {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}
          <Link to={paths.signUp()} data-test='sign-up'>
            {tr('FRONTEND.LOGIN.SIGNUP_BUTTON', 'Sign up')}
          </Link>
        </SignupLinkWrapper>
      </LoginContent>
    </LoginContentWrapper>
  )
}
